import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Row, notification } from 'antd'
import axios from 'axios'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeGlobalError } from '../../../../../redux/ducks/globalError'
import QrSbpModal from '../../Modals/QRSbpModal/QrSbpModal'

const { confirm } = Modal
const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL

const BtnsBlock = ({ record, settings, settingsNew }) => {
	const user = useSelector(state => state.user.data)
	const dispatch = useDispatch()
	const config = useSelector(state => state.config)
	const [qr, setQr] = useState(false)

	const showRefundConfirm = props => {
		confirm({
			okText: 'Да',
			cancelText: 'Нет',
			title: 'Заявление на снятие',
			icon: <ExclamationCircleOutlined />,
			content: 'Подтвердите отправку заявления на снятие взноса',

			onOk() {
				return axios
					.post(`${BASE_API_PATH}/refund/send_request`, {
						sessionId: localStorage.getItem('uid_ses'),
						clientId: props.clientId,
						recordId: props.recordId,
						basisId: props.basisId,
						basisType: props.basisType,
						sum: props.sum,
						companyId: config.code
					})
					.then(response => {
						if (response.data.return.КодОшибки === '0') {
							notification.success({
								message: 'Успешно',
								description: 'Заявление оформлено'
							})
						} else {
							notification.error({
								message: 'Ошибка',
								description: response.data.return.ОписаниеОшибки
							})
						}
					})
					.catch(err => {
						if (err.response) {
							console.log(err.response)
							dispatch(changeGlobalError('server_error'))
						} else {
							dispatch(changeGlobalError('no_internet'))
						}
					})
			},

			onCancel() {}
		})
	}

	return (
		<>
			{settings.ОтображатьЗаявлениеНаВозврат && (
				<Row className='card-row'>
					<div className='card-row-btns'>
						<Button
							type='primary'
							className='card-row-btn ant-btn-primary'
							onClick={() => {
								showRefundConfirm({
									clientId: user.ИнформацияОКлиенте?.UIDКлиента,
									recordId: record.Взнос.UIDВзноса,
									basisId: record.Основание?.UIDДокумента,
									basisType: record.Основание?.ВидДокумента,
									sum: record.ОстатокНоминал
									//visible: showRefund,
								})
							}}
						>
							Заявление на снятие
						</Button>
					</div>
				</Row>
			)}

			{/* Пополнить QR-кодом */}
			{!settingsNew.ОтображатьОплатитьQRКодомСБП && (
				<Row className='card-row'>
					<div className='card-row-btns'>
						<Button
							type='primary'
							className='card-row-btn ant-btn-primary'
							onClick={() => {
								setQr('sber')
							}}
						>
							Пополнить QR-кодом
						</Button>
					</div>
					<QRPaymentModal
						visible={qr}
						onCancel={() => setQr(false)}
						title='Пополнить QR-кодом'
						initVals={{
							UIDВзноса: record.Взнос.UIDВзноса,
							ВидДоговора: 'ПлатежПоВзносу',
							Сумма: '0',
							ОтчетКодСбер: qr === 'sber' ? true : false
						}}
					/>
				</Row>
			)}

			{/* СБП */}
			{settingsNew.ОтображатьОплатитьQRКодомСБП && (
				<Row className='card-row'>
					<div className='card-row-btns'>
						<Button
							type='primary'
							className='card-row-btn ant-btn-primary'
							onClick={() => {
								setQr('sbp')
							}}
						>
							Пополнить QR-кодом СБП
						</Button>
					</div>
					<QrSbpModal
						visible={qr}
						onCancel={() => setQr(false)}
						title={'Оплатить QR-кодом CБП'}
						initVals={{
							UIDДоговора: record.UIDДоговора,
							ВидДоговора: 'ПлатежПоВзносу',
							Сумма: 0,
							ТипОперации: 'ПоступлениеСредствНаВзнос',
							СБП: true
						}}
					/>
				</Row>
			)}

			{/*<RefundModal
            clientId={user.ИнформацияОКлиенте?.UIDКлиента}
            recordId={record.Взнос.UIDВзноса}
            basisId={record.Основание?.UIDДокумента}
            basisType={record.Основание?.ВидДокумента}
            sum={record.ОстатокНоминал}
            visible={showRefund}
            onCancel={() => {
            setShowRefund(false);
        }} />*/}
		</>
	)
}

export default memo(BtnsBlock)
